<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import { mapGetters } from 'vuex';
import WebsiteSearch from "@/components/nestedSearch/nested-search.vue";

export default {
    props: {

        title: String
    },
    components: {
        Layout,
        PageHeader,
        WebsiteSearch,
    },

    data() {
        return {
            trackedPagesData: [],
            page: 1,
            perPage: 30,
            pages: [],
            isAuditing: false,
            googleInsightResults: null,

            seoScoreConfig: {
                chart: {
                    height: 250,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['SEO'],
            },

            bestPracticeConfig: {
                chart: {
                    height: 250,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['Best Practice'],
            },

            performanceScoreConfig: {
                chart: {
                    height: 250,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: ['Performance'],
            }
        };
    },

    mounted() {

    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId', 'selectedOrganisationId'
        ])
    },

    methods: {
        getAllTrackedPages() {
            if (typeof this.selectedOrganisationId === 'undefined' || typeof this.selectedWebisteId === 'undefined') {
                return;
            }

            api.getAllTrackedPages(this.selectedWebisteId, this.selectedORganisationId)
                .then(response => {
                    console.log("tracked pages resp:", response);
                    this.trackedPagesData = response.data;
                });

        },

        handleOnPageSelected(pageInfoObj) {
         /*   if (this.isAuditing === false) {
                console.log('firing');
                this.isAuditing = true;
                this.googleInsightResults = null;

                api.auditPage(this.selectedOrganisationId, this.selectedWebisteId, pageInfoObj.id)
                    .then(response => {
                        this.googleInsightResults = response.data;

                    }).finally(() => {
                        this.isAuditing = false;
                    });
            }*/

            var query = {};

            query.organisationId = this.selectedOrganisationId;
            query.websiteId = this.selectedWebisteId;
            query.pageId = pageInfoObj.id;

            this.$router.push({ name: "website-page-seo-insights", query: query });

        },
    }
}
</script>
            
<template>
    <Layout>
        <PageHeader :title="title" />

        <div class="row">
            <div class="col">
                <div class="h-100">
                    <div class="row mb-3 pb-1">
                        <div class="card card-height-100">
                            <div class="card-header align-items-center d-flex">
                                <p class="
                                    text-uppercase
                                    fw-bold
                                    text-truncate
                                    mb-0
                                    ">
                                    Audit Technical SEO
                                </p>
                            </div>
                            <!-- end card header -->

                            <!-- card body -->
                            <div class="card-body">
                                <p>Select a page and we will run an audit of your web page.</p>
                                <WebsiteSearch @onPageSelected="handleOnPageSelected" :websiteId="selectedWebisteId">
                                </WebsiteSearch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Page search -->

        <div v-if="isAuditing">
            <div class="row">
                <div class="col text-center" >
                    <div class="spinner-border text-primary"  style="width: 8rem; height: 8rem;"  role="status">
                    </div>
                    <p> <span>Running tests...</span></p>
                </div>
            </div>
        </div>
    </Layout>
</template>

